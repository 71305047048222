<template>
  <v-container fluid>
    <v-row
      class="rounded elevation-3"
      style="background-color: white;">
      <v-col
        cols="12"
        class="d-flex flex-row justify-space-between align-center">
        <h1>
          Sales Products Report (POS Only)
        </h1>
        <v-btn
          color="success"
          :disabled="loading || !items.length"
          @click="exportCsv()">
          EXPORT ({{ items.length }})
        </v-btn>
      </v-col>
      <v-col
        cols="12"
        md="3"
        class="d-flex align-center date-picker-col">
        <date-range-picker
          v-model="queryDates"
          outlined />
      </v-col>
      <v-col
        cols="12"
        md="2">
        <WarehouseSelect
          v-model="query.warehouseId"
          :items="warehouses"
          item-text="name"
          item-value="id"
          :menu-props="{ offsetY: true }"
          label="Warehouses"
          outlined
          :loading="loadingAttribute"
          :disabled="loading" />
      </v-col>
      <v-col
        cols="12"
        md="3">
        <v-autocomplete
          v-model="query.collection"
          :items="collections"
          item-text="name"
          item-value="id"
          label="Collection"
          color="secondary"
          outlined
          dense
          hide-details
          clearable
          :loading="loadingAttribute"
          :disabled="loading" />
      </v-col>
      <v-col
        cols="12"
        md="3">
        <v-autocomplete
          v-model="query.groupCategories"
          :items="groupCategories"
          item-text="name"
          item-value="name"
          label="Group Categories"
          color="secondary"
          multiple
          chips
          small-chips
          deletable-chips
          outlined
          dense
          hide-details
          :loading="loadingAttribute"
          :disabled="loading" />
      </v-col>
      <v-col cols="1">
        <v-btn
          color="secondary"
          :disabled="query.warehouseId === null"
          style="width: 100%"
          @click="getReports()">
          FIND
        </v-btn>
      </v-col>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="items"
          sort-by="amount"
          sort-desc
          :loading="loading">
          <template #[`item.name`]="{ item }">
            <div
              class="d-flex flex-row my-2"
              style="gap: 8px;">
              <img
                v-if="item.images && item.images.length"
                :src="item.images[0]"
                :alt="item.model" />
              <img
                v-else
                :src="noPicture"
                :alt="item.code" />
              <div class="d-flex flex-column">
                <span class="font-weight-bold">
                  {{ item.name }}
                </span>
                <span>
                  {{ item.code }}
                </span>
                <span class="text-uppercase">
                  {{ item.color }} - {{ item.size }}
                </span>
                <span>
                  {{ item.brand }}
                </span>
              </div>
            </div>
          </template>
          <template #[`item.gwCollection`]="{ item }">
            {{ item.gwCollection ? item.gwCollection.name : '-' }}
          </template>
          <template #[`item.groupCategories`]="{ item }">
            <v-chip
              v-for="(category, index) in item.groupCategories"
              :key="`chip-${index}`"
              class="ma-1"
              color="secondary"
              text-color="white"
              small
              label>
              {{ category }}
            </v-chip>
          </template>
          <template #[`item.amount`]="{ item }">
            {{ item.amount | showNumberFormat() }}
          </template>
          <template #[`item.onHandQty`]="{ item }">
            {{ item.onHandQty | showNumberFormat() }}
          </template>
          <template #[`item.onReservedQty`]="{ item }">
            {{ item.onReservedQty | showNumberFormat() }}
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { ExportToCsv } from 'export-to-csv'
import NoPicture from '@/assets/image/no_picture_available.png'
import DateRangePicker from '@/components/DateRangePicker.vue'
import WarehouseSelect from '@/components/WarehouseSelect.vue'
import ProductCollectionProvider from '@/resources/ProductCollectionProvider'
import ProductGroupCategoryProvider from '@/resources/ProductGroupCategoryProvider'
import StoreReportsProvider from '@/resources/StoreReportsProvider'

const ProductCollectionService = new ProductCollectionProvider()
const ProductGroupCategoryService = new ProductGroupCategoryProvider()
const StoreReportsService = new StoreReportsProvider()

export default {
  components: {
    DateRangePicker,
    WarehouseSelect
  },
  data () {
    return {
      loadingAttribute: true,
      loading: false,
      noPicture: NoPicture,
      headers: [
        {
          text: 'Name',
          value: 'name',
          sortable: false
        },
        {
          text: 'Collection',
          value: 'gwCollection',
          align: 'center',
          sortable: false
        },
        {
          text: 'Group Categories',
          value: 'groupCategories',
          align: 'center',
          sortable: false
        },
        {
          text: 'Sales Qty.',
          value: 'amount',
          align: 'center'
        },
        {
          text: 'Stock',
          value: 'onHandQty',
          align: 'center'
        },
        {
          text: 'Reserved',
          value: 'onReservedQty',
          align: 'center'
        }
      ],
      items: [],
      collections: [],
      groupCategories: [],
      queryDates: [this.$dayjs().subtract(7, 'day').format('YYYY-MM-DD'), this.$dayjs().format('YYYY-MM-DD')],
      query: {
        warehouseId: null,
        collection: null,
        groupCategories: []
      }
    }
  },
  computed: {
    warehouses () {
      return this.$store.getters['Store/mapWarehouse']?.filter((v) => v.id !== 0) || []
    }
  },
  mounted () {
    this.getAttributes()
  },
  methods: {
    async getAttributes () {
      try {
        this.loadingAttribute = true

        const { data: collections } = await ProductCollectionService.getAll({
          page: 1,
          limit: 999,
          sortBy: 'createdAt',
          sortOrder: 'desc'
        })

        const { data: groupCategories } = await ProductGroupCategoryService.getAll({
          page: 1,
          limit: 999,
          sortBy: 'name',
          sortOrder: 'asc'
        })

        this.collections = collections.results
        this.groupCategories = groupCategories.results
      } catch (error) {
        console.error('getAttributes', error)
        this.$store.dispatch('Components/setSnackbar', {
          value: true,
          message: error.message || error,
          type: 'error'
        })
      } finally {
        this.loadingAttribute = false
      }
    },
    async getReports () {
      if (!this.query.warehouseId) {
        return
      }

      try {
        this.loading = true

        const { data } = await StoreReportsService.getSalesProducts({
          ...this.query,
          startDate: `${this.queryDates[0]}T00:00:00.000+07:00`,
          endDate: `${this.queryDates[1]}T23:59:59.999+07:00`
        })

        this.items = data
      } catch (error) {
        console.error('getReports', error)
        this.$store.dispatch('Components/setSnackbar', {
          value: true,
          message: error.message || error,
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    },
    async exportCsv () {
      try {
        this.loading = true

        const warehouse = this.warehouses.find((v) => v.id === this.query.warehouseId)
        const mapped = this.items.map((item) => ({
          code: item.code,
          color: item.color,
          size: item.size,
          brand: item.brand,
          collection: item.gwCollection?.name || '',
          group_categories: item.groupCategories.join(', '),
          sales_qty: item.amount,
          stock: item.onHandQty,
          reserved: item.onReservedQty
        }))

        const options = {
          filename: `Sales_Products_Report_${warehouse.name}`,
          showLabels: true,
          useKeysAsHeaders: true
        }

        const csvExporter = new ExportToCsv(options)
        csvExporter.generateCsv(mapped)
      } catch (error) {
        console.error('exportCsv', error)
        this.setSnackbar({
          value: true,
          message: error?.message || error,
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped>
img {
  max-height: 80px;
}
</style>
